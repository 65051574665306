import BaseCRUDResource from '../../BaseCRUDResource';
import { Id } from '../../../types/base';
import Api from '../../Api';

class ProjectTimesheetWeekResource extends BaseCRUDResource {
  objectIdentifier = 'project-timesheet-weeks';

  copyLastWeek = (date: string, teamMemberId: Id) => {
    return Api.client.post(`${this.objectIdentifier}/copy-last-week/`, {
      date,
      teamMemberId,
    });
  };

  generateStatement = (from: string, to: string, teamMemberId?: Id) => {
    return Api.client.post(
      `${this.objectIdentifier}/generate-statement/`,
      {
        from,
        to,
        teamMemberId,
      },
      { responseType: 'blob' }
    );
  };

  listWithHolidays = (filter: {
    teamMemberId: string;
    firstWeekDay: string;
  }) => {
    return Api.client.get('project-timesheet-weeks-with-holidays', {
      params: { filter },
    });
  };

  listWithCost = (filters?: {} | null) => {
    return Api.client.get('project-timesheet-weeks-with-cost', {
      params: filters,
    });
  };
}

export default new ProjectTimesheetWeekResource();
