import Api from '../../Api';

class CostResource {
  getCostsByMonth = async (params?: { from?: string; to?: string }) => {
    return await Api.client.get<CostsByMonthData>('costs-by-month', { params });
  };
}

export type CostByMonth = {
  month: string;
  totalCost: number;
  teamMembers: { [x: string]: number };
};

export type CostsByMonthData = CostByMonth[];

export default new CostResource();
