import React from 'react';
import { Id } from '../../../types/base';
import { getCurrencyUnit, PROJECT_ITEM_TYPES } from '../../projects/constants';
import { InvoiceEntry, InvoiceWithRelations } from '../types';
import { formatBudgetCurrency } from '../../projects/projectItems/TableFooter';
import { roundToTwo } from '../../../utils/numberUtils';
import InvoiceEntryFormFields from './InvoiceEntryFormFields';
import { useContainer } from 'unstated-next';
import { FULL_ACCESS_PERMISSION } from '../../team/permission';
import Delete from './DeleteEntry';
import ResourceTable from '../../../containers/resourceTable/ResourceTable';
import TableFooter from './TableFooter';
import { InvoiceEntriesContainer, ProjectItemsContainer } from '../Table';

type Props = {
  invoiceId: Id;
  invoiceCurrency: string;
  invoice: InvoiceWithRelations;
  onReload?: () => void;
};

export const InvoiceContext = React.createContext<{
  currency: string;
  invoice: InvoiceWithRelations | null;
}>({ currency: 'lv', invoice: null });

const Table = (props: Props) => {
  const { invoiceCurrency, invoice, onReload } = props;

  const config = [
    {
      label: 'Ref',
      accessor: (invoiceEntry: InvoiceEntry) =>
        PROJECT_ITEM_TYPES.find(
          (item) => item.value === invoiceEntry.projectItem?.type
        )?.text || 'Sum',
      key: 'ref',
      orderingField: 'projectItemId',
    },
    {
      label: 'Description',
      accessor: 'description' as const,
      key: 'description',
      orderingField: 'description',
      editable: {
        component: {
          type: 'text' as const,
        },
        valueAccessor: 'description' as const,
      },
      cellWidth: '5' as const,
    },
    {
      label: 'Description (BG)',
      accessor: 'descriptionBG' as const,
      key: 'descriptionBG',
      orderingField: 'descriptionBG',
      editable: {
        component: {
          type: 'text' as const,
        },
        valueAccessor: 'descriptionBG' as const,
      },
      cellWidth: '5' as const,
    },
    {
      label: "Q'ty",
      accessor: 'quantity' as const,
      key: 'quantity',
      orderingField: 'quantity',
      editable: {
        component: {
          type: 'number' as const,
          props: {
            rules: {
              min: { value: 0, message: 'Negative numbers are not allowed' },
            },
          },
        },
        valueAccessor: 'quantity' as const,
      },
    },
    {
      label: 'Rate',
      accessor: 'rate' as const,
      key: 'rate',
      orderingField: 'rate',
      editable: {
        component: {
          type: 'number' as const,
          props: {
            unit: getCurrencyUnit(invoiceCurrency),
            unitPosition:
              getCurrencyUnit(invoiceCurrency) === 'lv' ? 'right' : 'left',
          },
        },
        valueAccessor: 'rate' as const,
      },
    },
    {
      label: 'Net',
      accessor: (invoiceEntry: InvoiceEntry) =>
        formatBudgetCurrency(
          getCurrencyUnit(invoiceCurrency),
          roundToTwo(+(invoiceEntry?.net || 0))
        ),
      key: 'net',
      orderingField: 'net',
    },
  ];

  const createInstanceConfig = {
    title: 'Create new invoice entry',
    formFields: InvoiceEntryFormFields,
    formId: 'invoice-entry-create-form',
    buttonSize: 'large' as const,
  };

  const projectItemsContainer = useContainer(ProjectItemsContainer);

  return (
    <InvoiceContext.Provider value={{ currency: invoiceCurrency, invoice }}>
      <ResourceTable
        tableId="invoice-entries"
        draggableSort
        addSearch={false}
        footerComponent={TableFooter}
        columns={config}
        newInstanceConfig={createInstanceConfig}
        stateContainer={InvoiceEntriesContainer}
        onReload={onReload}
        onEdit={(id, params) => {
          if (params.quantity || params.rate) {
            projectItemsContainer.list();
          }
        }}
        onCreate={() => {
          projectItemsContainer.list();
        }}
        bulkActions={[
          {
            key: 'delete',
            component: Delete,
            permission: FULL_ACCESS_PERMISSION,
            showAsDisabled: true,
          },
        ]}
      />
    </InvoiceContext.Provider>
  );
};

export default Table;
