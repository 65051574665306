import { Id } from '../../types/base';

export type TeamMemberMonthlySalary = {
  id: Id;
  createDate: string;
  base: string;
  previewBase: string;
  bonus: string;
  total: string | null;
  previewPerformanceComponentTotal: string | null;
  previewYearlyAverage: string | null;
  previewMonthlyAverage: string | null;
  performanceComponent: string;
  performanceComponentWithDeduction: string;
  sixMonthsSalaryGrowth: string;
  bankCardPayment: string;
  unpaidLeaveDeduction: string;
  unpaidLeaveDeductionBankCard: string;
  monthlyAverage: string;
  yearlyAverage: string;
  overtime: string | null;
  productivityRate: number;
  teamMemberId: Id;
  monthlySalariesId: Id;
  monthlySalaries: MonthlySalaries;
};

export type MonthlySalaries = {
  createDate: string;
  id: Id;
  locked: boolean;
  month: string;
  skipPerformanceComponent: boolean;
};

export enum SalaryNoteType {
  GENERAL,
  MONTHLY,
}

export type MonthlyProjectBonus = {
  createDate: string;
  id: Id;
  bonusSum: string;
  teamMemberBonuses: {
    [teamMemberId: string]: number;
  };
  teamMemberCoefficients: {
    [teamMemberId: string]: number;
  };
  notes?: string;
  projectId: string;

  projectTimeSpentStartDate?: string;
  projectTimeSpentEndDate?: string;

  topDogsBonusPercentage: number;
  topDogsBonus: number;
  topDogsBonuses: {
    [roleId: string]: {
      bonus: number;
      bonusPercentage: number;
      teamMembers: {
        [teamMemberId: string]: { bonus: number; bonusPercentage: number };
      };
    };
  };

  bigChiefsBonus: number;
  bigChiefsBonusPercentage: number;
  bigChiefsBonuses: {
    [teamMemberId: string]: {
      bonus: number;
      bonusPercentage: number;
    };
  };
};

export type MonthlyProjectBonusNotes = {
  id: Id;
  notes: string;
  projectId: string;
  monthlySalariesId: string;
};

export type MonthlySalariesAndRelations = MonthlySalaries & {
  teamMemberMonthlySalaries: TeamMemberMonthlySalary[];
  monthlyProjectBonuses: MonthlyProjectBonus[];
  monthlyProjectBonusNotes: MonthlyProjectBonusNotes[];
};

export type MonthlySalariesFindByDateResponse = {
  monthlySalaries: MonthlySalariesAndRelations;
  hasNext: boolean;
  hasPrevious: boolean;
};
