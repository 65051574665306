import React, { useEffect, useMemo, useState } from 'react';
import useAPIRequest from '../../../hooks/useAPIRequest';
import TeamMemberSalariesResource from '../../../api/resources/salaries/TeamMemberSalariesResource';
import styled from 'styled-components';
import Table from '../../../components/tables/Table';
import Header from '../../../components/tables/Header';
import Row from '../../../components/tables/Row';
import HeaderCell from '../../../components/tables/HeaderCell';
import { addMonths, eachMonthOfInterval } from 'date-fns';
import { dateToString, toDateObject } from '../../../utils/dateUtils';
import { SalaryNoteType, TeamMemberMonthlySalary } from '../../salaries/types';
import { TableBody } from 'semantic-ui-react';
import Cell from '../../../components/tables/Cell';
import NotesField from '../../salaries/teamMemberSalaries/NotesField';
import { TeamMember } from '../types';
import TeamMemberSalaryNotesResource from '../../../api/resources/salaries/TeamMemberSalaryNotesResource';
import { StyledButton } from '../../salaries/teamMemberSalaries/ShowLockActions';

const Wrapper = styled.div`
  border-radius: 20px !important;
  padding: 20px 20px 10px 0;
  overflow-x: auto;
  background: #19442e;

  .salaries-chronology.ui.table.compact {
    font-size: 14px;
  }

  .salaries-chronology.ui.table tr td:first-child,
  .salaries-chronology.ui.table tr th:first-child {
    position: sticky;
    left: 0;
    z-index: 10;
    background: #19442e;
    font-weight: bold;
    border-radius: 0;
  }

  .salaries-chronology.ui.table thead th,
  .salaries-chronology.ui.table,
  .salaries-chronology td {
    background-color: transparent;
    color: #fff;
  }

  .salaries-chronology.ui.table thead th,
  .salaries-chronology.ui.table.celled td {
    border-left: none;
    border-right: none;
    padding-top: 4px;
    padding-bottom: 4px;
  }

  .salaries-chronology.ui.table {
    border: none !important;
  }

  .salaries-chronology.ui.table.ui.table.inverted
    tr:first-child
    td:focus-within,
  .salaries-chronology.ui.table.ui.celled.table.inverted tr td:focus-within,
  .salaries-chronology.ui.table.ui.table.inverted tr td:focus-within {
    border: none !important;
    border-top: 1px solid rgba(255, 255, 255, 0.1) !important;
  }
`;

const findSalaryForMonth = (
  month: Date,
  teamMemberSalaries: TeamMemberMonthlySalary[]
) => {
  return teamMemberSalaries?.find((salary: TeamMemberMonthlySalary) => {
    return (
      new Date(salary.monthlySalaries.month).getMonth() === month.getMonth() &&
      new Date(salary.monthlySalaries.month).getFullYear() ===
        month.getFullYear()
    );
  });
};

const formatterFunction = new Intl.NumberFormat('bg', {
  style: 'currency',
  currency: 'BGN',
  minimumFractionDigits: 0,
  maximumFractionDigits: 2,
}).format;

const SalariesChronology = ({ instance }: { instance: TeamMember }) => {
  const { performRequest: getSalaries, data: salariesResponse } = useAPIRequest(
    TeamMemberSalariesResource.list
  );
  const [showLegacyTotal, setShowLegacyTotal] = useState(false);

  const { performRequest: updateNote } = useAPIRequest(
    TeamMemberSalaryNotesResource.createOrUpdateMonthlySalaryNote
  );

  const {
    data: teamMembersNotes,
    performRequest: getTeamMemberNotes,
  } = useAPIRequest(TeamMemberSalaryNotesResource.list);

  const { id: teamMemberId, startDate: teamMemberStartDate } = instance;

  useEffect(() => {
    getSalaries({
      filter: {
        where: {
          teamMemberId: teamMemberId,
        },
        include: [{ relation: 'monthlySalaries' }],
      },
    });
    getTeamMemberNotes({
      filter: {
        where: {
          teamMemberId,
          type: SalaryNoteType.MONTHLY,
        },
      },
    });
  }, [getSalaries, teamMemberId, getTeamMemberNotes]);

  useEffect(() => {
    const monthToScrollTo = addMonths(new Date(), 4);
    const element = document.getElementById(
      `${monthToScrollTo.getFullYear()}-${monthToScrollTo.getMonth()}`
    );
    element?.scrollIntoView({ inline: 'end' });
  }, []);

  const months = useMemo(() => {
    return eachMonthOfInterval({
      start: toDateObject(teamMemberStartDate),
      end: addMonths(new Date(), 18),
    });
  }, [teamMemberStartDate]);

  const data = useMemo(() => {
    return {
      legacyTotal: months.map((month) => {
        const salaryForMonth = findSalaryForMonth(
          month,
          salariesResponse?.data
        );
        return salaryForMonth?.previewPerformanceComponentTotal;
      }),
      base: months.map((month) => {
        return findSalaryForMonth(month, salariesResponse?.data)?.base;
      }),
      unpaidLeaveDeduction: months.map((month) => {
        return findSalaryForMonth(month, salariesResponse?.data)
          ?.unpaidLeaveDeduction;
      }),
      performanceComponent: months.map((month) => {
        const salaryForMonth = findSalaryForMonth(
          month,
          salariesResponse?.data
        );
        if (salaryForMonth?.monthlySalaries.skipPerformanceComponent) {
          return undefined;
        }
        return salaryForMonth?.performanceComponent;
      }),
      overtime: months.map((month) => {
        return findSalaryForMonth(month, salariesResponse?.data)?.overtime;
      }),
      bonus: months.map((month) => {
        return findSalaryForMonth(month, salariesResponse?.data)?.bonus;
      }),
      bankCard: months.map((month) => {
        return findSalaryForMonth(month, salariesResponse?.data)
          ?.bankCardPayment;
      }),
      productivity: months.map((month) => {
        return findSalaryForMonth(month, salariesResponse?.data)
          ?.productivityRate;
      }),
      monthlyAverage: months.map((month) => {
        return findSalaryForMonth(month, salariesResponse?.data)
          ?.monthlyAverage;
      }),
      yearlyAverage: months.map((month) => {
        return findSalaryForMonth(month, salariesResponse?.data)?.yearlyAverage;
      }),
      previewMonthlyAverage: months.map((month) => {
        return findSalaryForMonth(month, salariesResponse?.data)
          ?.previewMonthlyAverage;
      }),
      previewYearlyAverage: months.map((month) => {
        return findSalaryForMonth(month, salariesResponse?.data)
          ?.previewYearlyAverage;
      }),
      sixMonthsSalaryGrowth: months.map((month) => {
        return findSalaryForMonth(month, salariesResponse?.data)
          ?.sixMonthsSalaryGrowth;
      }),
    };
  }, [months, salariesResponse]);

  return (
    <Wrapper>
      <Table
        stackable
        className="salaries-chronology"
        inverted={true}
        selectable={false}
        striped={false}
      >
        <Header>
          <Row>
            <HeaderCell style={{ whiteSpace: 'nowrap' }}>
              legacy
              {showLegacyTotal ? (
                <StyledButton
                  basic
                  icon="caret up"
                  size="big"
                  inverted
                  title="Hide legacy"
                  onClick={() => {
                    setShowLegacyTotal(false);
                  }}
                />
              ) : (
                <StyledButton
                  basic
                  icon="caret down"
                  size="big"
                  inverted
                  title="Show legacy"
                  onClick={() => {
                    setShowLegacyTotal(true);
                  }}
                />
              )}
            </HeaderCell>
            {months.map((month) => {
              const salaryForMonth = findSalaryForMonth(
                month,
                salariesResponse?.data
              )?.total;

              return (
                <HeaderCell
                  key={month.toISOString()}
                  style={{
                    textAlign: 'center',
                    padding: '2px 5px',
                  }}
                  id={`${month.getFullYear()}-${month.getMonth()}`}
                >
                  <div
                    style={{
                      padding: '2px 10px',
                      borderRadius: '10px',
                      backgroundColor: '#00a650',
                      height: '45px',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                    }}
                  >
                    <p style={{ margin: 0 }}>
                      {month.toLocaleString('en-US', {
                        month: 'short',
                        year: 'numeric',
                      })}
                    </p>
                    <p style={{ margin: 0 }}>
                      {Number(salaryForMonth)
                        ? formatterFunction(Number(salaryForMonth))
                        : ' '}
                    </p>
                  </div>
                </HeaderCell>
              );
            })}
          </Row>
        </Header>
        <TableBody>
          {showLegacyTotal && (
            <Row>
              <Cell style={{ whiteSpace: 'nowrap' }}>legacy total</Cell>
              {data.legacyTotal.map((value, index) => (
                <Cell key={index} style={{ padding: 0 }}>
                  <div
                    style={{
                      padding: '2px 10px',
                      borderRadius: '10px',
                      backgroundColor: '#00a650',
                      height: '25px',
                      display: 'flex',
                      flexDirection: 'column',
                      justifyContent: 'center',
                    }}
                  >
                    {value ? formatterFunction(Number(value)) : '-'}
                  </div>
                </Cell>
              ))}
            </Row>
          )}
          {showLegacyTotal && (
            <Row>
              <Cell>legacy monthly</Cell>
              {data.previewMonthlyAverage.map((value, index) => (
                <Cell key={index}>
                  {value ? formatterFunction(Number(value)) : '-'}
                </Cell>
              ))}
            </Row>
          )}
          {showLegacyTotal && (
            <>
              <Row style={{ borderBottomWidth: '2px' }}>
                <Cell>legacy annual</Cell>
                {data.previewYearlyAverage.map((value, index) => (
                  <Cell key={index} style={{ borderBottomWidth: '2px' }}>
                    {value ? formatterFunction(Number(value)) : '-'}
                  </Cell>
                ))}
              </Row>
            </>
          )}
          <Row>
            <Cell style={{ paddingTop: '10px' }}>base</Cell>
            {data.base.map((value, index) => (
              <Cell key={index} style={{ paddingTop: '10px' }}>
                {value ? formatterFunction(Number(value)) : '-'}
              </Cell>
            ))}
          </Row>
          <Row>
            <Cell>unpaid leave</Cell>
            {data.unpaidLeaveDeduction.map((value, index) => (
              <Cell key={index}>
                {Number(value) ? `-${formatterFunction(Number(value))}` : '-'}
              </Cell>
            ))}
          </Row>
          <Row>
            <Cell>perf. component</Cell>
            {data.performanceComponent.map((value, index) => (
              <Cell key={index}>
                {value ? formatterFunction(Number(value)) : '-'}
              </Cell>
            ))}
          </Row>
          <Row>
            <Cell>overtime</Cell>
            {data.overtime.map((value, index) => (
              <Cell key={index}>
                {value ? formatterFunction(Number(value)) : '-'}
              </Cell>
            ))}
          </Row>
          <Row>
            <Cell>bonus</Cell>
            {data.bonus.map((value, index) => (
              <Cell key={index}>
                {value ? formatterFunction(Number(value)) : '-'}
              </Cell>
            ))}
          </Row>
          <Row>
            <Cell>bank card</Cell>
            {data.bankCard.map((value, index) => (
              <Cell key={index}>
                {value ? formatterFunction(Number(value)) : '-'}
              </Cell>
            ))}
          </Row>
          <Row>
            <Cell>prod. rate</Cell>
            {data.productivity.map((value, index) => (
              <Cell key={index}>{value || '-'}</Cell>
            ))}
          </Row>
          <Row>
            <Cell>monthly</Cell>
            {data.monthlyAverage.map((value, index) => (
              <Cell key={index}>
                {value ? formatterFunction(Number(value)) : '-'}
              </Cell>
            ))}
          </Row>
          <Row>
            <Cell>annual</Cell>
            {data.yearlyAverage.map((value, index) => (
              <Cell key={index}>
                {value ? formatterFunction(Number(value)) : '-'}
              </Cell>
            ))}
          </Row>
          <Row>
            <Cell>growth</Cell>
            {data.sixMonthsSalaryGrowth.map((value, index) => (
              <Cell key={index}>
                {value ? formatterFunction(Number(value)) : '-'}
              </Cell>
            ))}
          </Row>
          <Row>
            <Cell>prod. rate</Cell>
            {data.productivity.map((value, index) => (
              <Cell key={index}>{value || '-'}</Cell>
            ))}
          </Row>
          <Row>
            <Cell>notes</Cell>
            {months.map((month, index) => (
              <Cell key={index}>
                <NotesField
                  initialValue={
                    teamMembersNotes?.data?.find(
                      (note: { month: string; note: string }) =>
                        toDateObject(note.month).getMonth() ===
                          month.getMonth() &&
                        toDateObject(note.month).getFullYear() ===
                          month.getFullYear()
                    )?.note || ''
                  }
                  onSubmit={(value) => {
                    updateNote({
                      note: value,
                      teamMemberId,
                      month: dateToString(new Date(month.setDate(15))),
                    });
                  }}
                  maxRows={3}
                  minRows={3}
                />
              </Cell>
            ))}
          </Row>
        </TableBody>
      </Table>
    </Wrapper>
  );
};

export default SalariesChronology;
